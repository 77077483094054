import { reactive } from "vue";
import { regionSave, regionUpdate } from '@/request/dishApi';
import { ElMessage, ElLoading } from 'element-plus';

export default (templateId, getList) => {
    const state = reactive({
        show: false,
        type: 'add',//add-新增，edit-编辑，see-查看
        row: {}
    })

    // 打开
    function open(type, row) {
        state.show = true;
        state.type = type;
        state.row = row || {}
    }

    // 关闭
    function close() {
        state.show = false;
        state.type = 'add';
        state.row = {}
    }

    // 提交
    function submitFn(form) {
        switch (state.type) {
            case 'add':
                addFn(form)
                break;
            case 'edit':
                editFn(form)
                break;
            default:
                addFn(form)
        }
    }

    // 新建
    async function addFn(form) {
        const loadingInstance = ElLoading.service()
        const params = { name: form.name, templateId: templateId, provinces: form.provinces.join(',') }
        const { code } = await regionSave(params)
        loadingInstance.close()
        if (code === '0') {
            close()
            ElMessage({ type: 'success', message: '操作成功' })
            getList()
        }
    }

    // 编辑
    async function editFn(form) {
        const loadingInstance = ElLoading.service()
        const params = { name: form.name, id: state.row.id, provinces: form.provinces.join(',') }
        const { code } = await regionUpdate(params)
        loadingInstance.close()
        if (code === '0') {
            close()
            ElMessage({ type: 'success', message: '操作成功' })
            getList()
        }
    }

    return { state, open, close, submitFn }
}