import { ElMessageBox } from 'element-plus';
import { regionDel } from '@/request/dishApi';
import { ElMessage, ElLoading } from 'element-plus';

export default (getList) => {

    // 删除确认
    function deleteHandel(row) {
        ElMessageBox.confirm(
            '确认删除？',
            '提示',
            {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'error',
            }
        ).then(() => {
            deleteSend(row)
        })
    }

    // 删除请求
    async function deleteSend(row) {
        const loading = ElLoading.service()
        const { code } = await regionDel({ id: row.id })
        loading.close()
        if (code === '0') {
            getList()
            ElMessage({ type: 'success', message: '操作成功' })
        }
    }

    return { deleteHandel }
}