<template>
    <div class="custom_area">
        <section class="list_box">
            <div class="tit_box">
                <small-title tit="大区模板详情"></small-title>
            </div>
            <div class="search_box">
                <div class="area_name">
                    <span>大区模板名称</span>
                    <el-input class="int" placeholder="请输入大区模板名称" v-model="state.tempName" @blur="saveTempName">
                    </el-input>
                </div>
                <el-button type="primary" @click="detOpen('add')">新建大区</el-button>
            </div>
            <div class="table_box">
                <el-table :data="state.tableData" stripe border style="width: 100%" height="551"
                    :header-cell-style="{ background: '#f5f5f5', color: '#80899b', fontWeight: 400 }">
                    <el-table-column label="序号" width="50" align="center">
                        <template #default="scope">
                            {{ (state.query.pageNum - 1) * state.query.pageSize + scope.$index + 1 }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="大区名称" />
                    <el-table-column prop="provinces" label="省份" />
                    <el-table-column label="操作" width="150px" align="center">
                        <template #default="scope">
                            <el-button type="text" size="small" @click="detOpen('see', scope.row)">查看</el-button>
                            <el-button type="text" size="small" @click="detOpen('edit', scope.row)">编辑</el-button>
                            <el-button type="text" size="small" @click="deleteHandel(scope.row)"
                                style="color: #F56C6C;">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="no_data" v-if="state.tableData.length === 0">
                    <el-empty description="暂无数据"></el-empty>
                </div>
            </div>
            <div class="page_box">
                <el-pagination layout="prev, pager, next" background :page-size="state.query.pageSize"
                    v-model:current-page="state.query.pageNum" :total="state.total"></el-pagination>
            </div>
        </section>
    </div>
    <!-- 详情弹框 -->
    <details-dialog :show="detState.show" :type="detState.type" :row="detState.row" @close="detClose"
        :provinceList="provinceList" @submit="submitFn">
    </details-dialog>
</template>

<script setup>
import { onBeforeMount, reactive, watch } from 'vue';
import SmallTitle from '@/components/common/SmallTitle.vue';
import useDelete from './useDelete';
import DetailsDialog from './DetailsDialog.vue';
import useDetails from './useDetails';
import { useRoute } from 'vue-router';
import { regionGetList, templateInfo, templateUpdate } from '@/request/dishApi';
import useDict from './useDict';
import { ElMessage, ElLoading } from 'element-plus';

const $route = useRoute()

const state = reactive({
    id: $route.query.id,
    tempName: '',
    query: { pageNum: 1, pageSize: 10 },
    tableData: [],
    total: 0
})

// 获取大区模板详情
async function getDetails() {
    const { data } = await templateInfo({ id: state.id })
    state.tempName = data.name || ''
}

// 获取大区列表
async function getList() {
    const params = { ...state.query, templateId: state.id }
    const { data } = await regionGetList(params)
    state.tableData = data?.list || [];
    state.total = data?.total || 0;
}

// 分页
watch(() => state.query.pageNum, () => {
    getList()
})

// 省份字典
const { provinceList } = useDict()

// 删除
const { deleteHandel } = useDelete(getList)

// 编辑/查看
const { state: detState, open: detOpen, close: detClose, submitFn } = useDetails(state.id, getList)

// 修改大区模板名称
async function saveTempName() {
    const loading = ElLoading.service()
    const { code } = await templateUpdate({ id: state.id, name: state.tempName })
    setTimeout(() => { loading.close() }, 500)
    if (code === '0') {
        ElMessage({ type: 'success', message: '修改大区模板名称成功' })
    }
}

onBeforeMount(() => {
    getDetails()
    getList()
})
</script>

<style scoped lang="scss">
.custom_area {
    padding: 20px 40px;

    .list_box {
        background-color: #fff;
        border-radius: 14px;
        padding: 20px;

        .tit_box {
            padding-bottom: 20px;
        }

        .search_box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .area_name {
                display: flex;
                align-items: center;

                span {
                    padding-right: 5px;
                }

                .int {
                    width: 250px;
                }

            }
        }

        .table_box {
            padding-top: 20px;
            position: relative;

            .no_data {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                padding-top: 180px;
            }
        }

        .page_box {
            text-align: right;
            padding: 20px 0;
        }
    }
}
</style>