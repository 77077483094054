<template>
    <el-dialog :model-value="show" :title="dialogTitle" width="600px" @close="closeHandle">
        <el-form ref="formRef" :model="state.form" :rules="state.rules" label-width="70px" label-position="top"
            :disabled="type === 'see'">
            <el-form-item prop="name" label="自定义大区名称">
                <el-input v-model.trim="state.form.name" placeholder="请输入" />
            </el-form-item>
            <el-form-item prop="provinces" label="自定义大区省份">
                <el-select multiple style="width:100%" v-model="state.form.provinces">
                    <el-option v-for="item in provinceList" :key="item" :label="item" :value="item"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="closeHandle">关闭</el-button>
                <el-button type="primary" @click="submitForm(formRef)" v-if="type !== 'see'">确定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { computed, reactive, ref, watch } from 'vue';

const props = defineProps({
    show: { type: Boolean, required: true },
    type: { type: String, required: true },
    row: { type: Object, default: {} },
    provinceList: { type: Array, required: true }
})

const emits = defineEmits(['submit', 'close'])

const formRef = ref(null)

const state = reactive({
    form: { name: '', provinces: [] },
    rules: {
        name: [{ required: true, message: '请输入大区名称', trigger: 'blur' }],
        provinces: [{ required: true, message: '请选择省份', trigger: 'change' }]
    }
})

// 弹框标题
const dialogTitle = computed(() => {
    const obj = {
        add: '新建大区模板',
        edit: '编辑大区模板',
        see: '查看大区模板'
    }
    return obj[props.type]
})

watch(() => props.show, (newVal) => {
    if (newVal) {
        if (props.type === 'see' || props.type === 'edit') {
            // 回显
            state.form.name = props.row.name;
            state.form.provinces = props.row.provinces.split(',');
        }
    }
})

// 关闭
function closeHandle() {
    formRef.value.resetFields()
    emits('close')
}

// 提交
function submitForm(formEl) {
    if (!formEl) return;
    formEl.validate((valid) => {
        if (valid) {
            emits('submit', state.form)
        }
    })
}
</script>