import { onBeforeMount, ref } from 'vue';
import { dishDict } from '@/request/dishApi';

export default () => {
    const provinceList = ref([])

    async function getProvinces() {
        const { data } = await dishDict({ code: 'province' })
        provinceList.value = data || []
    }

    onBeforeMount(() => {
        getProvinces()
    })

    return { provinceList }
}